import React from 'react'
import styled from 'styled-components'
import { ContainerRow } from './style'
import { Facebook, Youtube } from 'react-feather'

const StyledFooter = styled.footer`
  padding: 2.5rem 2.5rem;
  background-color: #fff;
  color: #000;
`

const Paragraph = styled.p`
  display: flex;
  font-size: 1.125rem;
  font-weight: 100;
  width: 100%;
  align-self: center;
`

const Links = styled.span`
  display: flex;
  justify-content: flex-end;
  width: 100%;

  a {
    padding-left: 1rem;
  }
`

class Footer extends React.Component {
  render() {
    return (
      <StyledFooter>
        <ContainerRow>
          <Paragraph>&copy; ELSO automatyka 2021</Paragraph>
          <Links>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.facebook.com/ELSO-Automatyka-146453396086232/"
            >
              <Facebook color="#35353E" size="30" aria-label="Facebook" />
            </a>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.youtube.com/channel/UCHDEyyqPrCZpysItOou4Mdw"
            >
              <Youtube color="#35353E" size="30" aria-label="Youtube" />
            </a>
          </Links>
        </ContainerRow>
      </StyledFooter>
    )
  }
}

export default Footer
