import React, { useState } from 'react'
import { Link } from 'gatsby'
import styled from 'styled-components'
import { ContainerRow } from './style'
import { Mail, Phone } from 'react-feather'
import { useIntl } from 'gatsby-plugin-intl'

const Navigation = styled.div`
  display: block;
  padding: 2.5rem 1.75rem;
  z-index: 3;
  background-color: ${(props) => (props.active ? '#f9f9f9' : '#ffffff')};

  @media (min-width: 800px) {
    background-color: #fff;
  }
`

const Logo = styled((props) => <Link {...props} />)`
  display: flex;
  flex-direction: column;
  font-size: 2.4rem;
  margin: 0;
  align-self: center;
  text-decoration: none;

  div > span {
    font: inherit;
    font-weight: 500;
    letter-spacing: 3px;
    color: #e22d24;
  }

  div > span:last-child {
    color: #3567b7;
  }

  span {
    font-size: 0.8rem;
    font-weight: 300;
    align-self: center;
    letter-spacing: 0.1em;
    color: #000;
  }
`

const StyledNav = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-self: center;

  @media (min-width: 800px) {
    width: 100%;
  }
`

const StyledLink = styled((props) => <Link {...props} />)`
  color: #000;
  font-weight: ${(props) => (props.$activepage ? 400 : 100)};
  text-decoration: none;
  margin: 0 0.6rem;
  letter-spacing: 0.1em;
  transition: color 0.1s;
  display: none;

  &:hover {
    color: #777;
  }

  @media (min-width: 800px) {
    display: block;
  }
`

const Contact = styled.div`
  display: none;
  width: 100%;
  justify-content: flex-end;

  a {
    font-size: 1rem;
    align-self: center;
    text-decoration: none;
    color: #000;
    padding: 0.2rem;
    transition: color 0.1s;

    &:hover {
      color: #777;
    }
  }

  svg {
    align-self: center;
    padding: 0 0.3rem;
  }

  div {
    display: flex;
    flex-direction: column;
    align-self: center;
  }

  @media (min-width: 800px) {
    display: flex;
  }
`

const MobileContact = styled.div`
  background-color: transparent;
  padding: 1.1rem 0;

  a {
    font-size: 1rem;
    align-self: center;
    text-decoration: none;
    color: #000;
    padding: 0.2rem;
  }

  svg {
    align-self: center;
  }

  ${ContainerRow} {
    justify-content: space-evenly;
  }

  @media (min-width: 800px) {
    display: none;
  }
`

const MobileContactItem = styled.div`
  display: flex;
  flex-direction: row;
`

const Tel = styled.div`
  display: flex;
  flex-direction: column;
  align-self: center;
`

const NavButtonIcon = styled.span`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  height: 4px;
  width: 80%;
  border-radius: 2px;

  &:before {
    content: '';
    position: absolute;
    top: -10px;
    right: 0;
    height: 4px;
    border-radius: 2px;
  }

  &:after {
    content: '';
    position: absolute;
    height: 4px;
    top: 10px;
    right: 0;
    border-radius: 2px;
  }
`

const MobileLink = styled((props) => <Link {...props} />)`
  display: flex;
  align-items: center;
  font-size: 1rem;
  font-weight: ${(props) => (props.$activepage ? 400 : 100)};
  text-decoration: none;
  color: rgba(0, 0, 0, 0.8);
  padding: 0 1rem;
  white-space: nowrap;

  &:hover {
    cursor: pointer;
    color: rgba(0, 0, 0, 0.4);
    transition: color 0.2s !important;
  }
`

const MobileNav = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${(props) => (props.active ? '1.2rem 0 0 0' : '0')};
  width: 100%;
  background-color: transparent;
  z-index: ${(props) => (props.active ? 3 : 0)};
  transition: 0.4s;
  opacity: ${(props) => (props.active ? 1 : 0)};

  ${MobileLink} {
    height: ${(props) => (props.active ? '3rem' : '0')};
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
    transition: 0.4s;
  }

  ${MobileContact} {
    height: ${(props) => (props.active ? 'auto' : '0')};
    visibility: ${(props) => (props.active ? 'visible' : 'hidden')};
    transition: 0.4s;
  }

  @media (min-width: 800px) {
    display: none;
  }
`

const MobileNavButton = styled.button`
  display: flex;
  background-color: transparent;
  border: none;
  outline: none;
  height: 3.5rem;
  width: 3rem;
  font-size: 2rem;
  align-items: center;
  justify-content: center;
  padding: 0.3px;
  cursor: pointer;

  @media (min-width: 800px) {
    display: none;
  }

  ${NavButtonIcon} {
    background-color: #000;
    transform: ${(props) => (props.active ? 'rotate(45deg)' : '')};
    transition: 0.2s;

    &:before {
      background-color: #000;
      transform: ${(props) => (props.active ? 'translateY(10px)' : '')};
      width: ${(props) => (props.active ? '100%' : '70%')};
      opacity: 1;
      transition: 0.2s;
    }

    &:after {
      background-color: #000;
      transform: ${(props) =>
        props.active ? 'translateY(-10px) rotate(90deg)' : ''};
      width: ${(props) => (props.active ? '100%' : '80%')};
      transition: 0.2s;
    }
  }
`

const NavbarRight = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  @media (min-width: 800px) {
    width: auto;
  }
`

const Navbar = (props) => {
  const intl = useIntl()
  const [isActive, setActive] = useState(false)

  const toggle = () => setActive(!isActive)

  return (
    <>
      <Navigation active={isActive}>
        <ContainerRow>
          <Logo to={`/`}>
            <div>
              <span>EL</span>
              <span>SO</span>
            </div>
            <span>automatyka</span>
          </Logo>
          <StyledNav>
            <StyledLink
              to={`/`}
              $activepage={props.activepage === 'home'}
            >
              {intl.formatMessage({ id: 'navbar_home' })}
            </StyledLink>
            <StyledLink
              to={`/realizacje`}
              $activepage={props.activepage === 'realizations'}
            >
              {intl.formatMessage({ id: 'navbar_realizations' })}
            </StyledLink>
          </StyledNav>
          <Contact>
            <Mail color="#3567b7" size="20" />
            <a href="mailto:biuro@elso-automatyka.pl">
              biuro@elso-automatyka.pl
            </a>
            <Phone color="#3567b7" size="20" />
            <div>
              <a href="tel:+48798993543">(+48) 798 993 543,</a>
              <a href="tel:+48660286301">(+48) 660 286 301</a>
            </div>
          </Contact>
          <NavbarRight>
            <MobileNavButton
              onClick={toggle}
              active={isActive}
              aria-label="Mobile navigation button"
            >
              <NavButtonIcon />
            </MobileNavButton>
          </NavbarRight>
        </ContainerRow>
        <MobileNav active={isActive}>
          <MobileLink
            to={`/`}
            $activepage={props.activepage === 'home'}
          >
            {intl.formatMessage({ id: 'navbar_home' })}
          </MobileLink>
          <MobileLink
            to={`/realizacje`}
            $activepage={props.activepage === 'realizations'}
          >
            {intl.formatMessage({ id: 'navbar_realizations' })}
          </MobileLink>
          <MobileContact>
            <ContainerRow>
              <MobileContactItem>
                <Mail color="#3567b7" size="20" />
                <a href="mailto:biuro@elso-automatyka.pl">
                  biuro@elso-automatyka.pl
                </a>
              </MobileContactItem>
              <MobileContactItem>
                <Phone color="#3567b7" size="20" />
                <Tel>
                  <a href="tel:+48798993543">(+48) 798 993 543,</a>
                  <a href="tel:+48660286301">(+48) 660 286 301</a>
                </Tel>
              </MobileContactItem>
            </ContainerRow>
          </MobileContact>
        </MobileNav>
      </Navigation>
    </>
  )
}

export default Navbar
