import React from 'react'
import styled from 'styled-components'
import { Container, Section } from './style'
import { useIntl } from 'gatsby-plugin-intl'

const StyledSection = styled(Section)`
  background-color: #35353e;
  border-radius: 0 50px 0 0;
  color: #fff;
`

const StyledContainer = styled(Container)`
  @media (min-width: 800px) {
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 800px) {
    div:first-child {
      margin-bottom: 3rem;
    }
  }
`

const H3 = styled.h3`
  font-size: 1.2rem;
  margin-bottom: 1rem;
`

const Paragraph = styled.p`
  font-size: 1.1rem;
  font-weight: 100;
  line-height: 25px;
  letter-spacing: 0.1rem;
`

const SubFooter = () => {
  const intl = useIntl()

  return (
    <StyledSection>
      <StyledContainer>
        <div>
          <H3>{intl.formatMessage({ id: 'subfooter_title_left' })}</H3>
          <Paragraph>
            ELSO AUTOMATYKA SP. Z.O.O. <br />
            SPÓŁKA KOMANDYTOWA <br />
            ul. Cmentarna 70 <br />
            43-100 Tychy <br />
          </Paragraph>
        </div>
        <div>
          <H3>{intl.formatMessage({ id: 'subfooter_title_right' })}</H3>
          <Paragraph>
            KRS: 0000705904 <br />
            NIP: 646-296-34-28 <br />
            REGON: 368889037 <br />
          </Paragraph>
        </div>
      </StyledContainer>
    </StyledSection>
  )
}

export default SubFooter
